var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-touch',{on:{"swipeup":_vm.shrinkDetail,"swipedown":_vm.shrinkDetail}},[(_vm.onlineDetail.extOrderStatusTxt)?_c('div',{staticClass:"infoDetail"},[_c('eventTag',{attrs:{"classes":'statusBox',"statusTxt":_vm.onlineDetail.extOrderStatusTxt,"status":_vm.onlineDetail.status}}),_c('div',{staticClass:"topBtn",on:{"click":_vm.shrinkDetail}},[_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":_vm.showsInfor ? '#iconarrow-bottom' : '#iconarrow-top'}})])]),_c('div',{staticClass:"mainInfor"},[_c('span',{staticClass:"iconBox"},[_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":'#' + _vm.onlineDetail.itsItemData.sourceIcon}})])]),_c('div',{staticClass:"infoMian"},[_c('p',[_vm._v(_vm._s(_vm.onlineDetail.itsItemData.sourceTxt))]),_c('span',[_vm._v(" "+_vm._s(_vm.onlineDetail.itsItemData.require_level_txt)+" "),(_vm.showsInfor)?[_vm._v(_vm._s(_vm.onlineDetail.itsItemData.departure_time || _vm.onlineDetail.itsItemData.typeTxt || _vm.onlineDetail.itsItemData.rule))]:_vm._e()],2)]),(_vm.showsInfor || _vm.onlineDetail.extOrderStatus == 100)?_c('div',{staticClass:"priceMain"},[_c('p',[_vm._v(" "+_vm._s(_vm._f("currencyFormatter")(_vm.onlineDetail.status == "-1" ? _vm.onlineDetail.settleAmount : (_vm.onlineDetail.status == "5" ? _vm.onlineDetail.settleAmount : _vm.onlineDetail.amount) || 0))+" ")]),_c('span',[_vm._v(_vm._s(_vm.onlineDetail.status == "-1" ? "违约金" : _vm.onlineDetail.status == "5" ? "实际金额" : "预计金额"))])]):(
            !_vm.$route.query.isApproval && !this.SmartStorage.get('viewOnly')
          )?_c('div',{staticClass:"funBtnBox"},[(_vm.onlineDetail.extOrderStatus == 50)?[_c('div',{on:{"click":function($event){_vm.isloding &&
                  _vm.submitOrder(_vm.onlineDetail, _vm.onlineDetail.itsButtons[0])}}},[_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":'#' + _vm.onlineDetail.itsButtons[0].css}})]),_vm._v(" "+_vm._s(_vm.onlineDetail.itsButtons[0].txt)+" ")]),_c('div',{on:{"click":function($event){return _vm.ihtml.telCall(
                  _vm.onlineDetail.extOrderDataDynamicData.driverMobile
                )}}},[_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icondianhua-"}})]),_vm._v(" 联系司机 ")])]:(_vm.onlineDetail.extOrderStatus != 100)?_vm._l((_vm.onlineDetail.itsButtons.slice(0, 2)),function(btn,index){return _c('div',{key:index,on:{"click":function($event){_vm.isloding && _vm.submitOrder(_vm.onlineDetail, btn)}}},[_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":'#' + btn.css}})]),_vm._v(" "+_vm._s(btn.txt)+" ")])}):_vm._e()],2):_vm._e()]),(_vm.showsInfor)?_c('div',{staticClass:"hideMain"},[_c('div',{staticClass:"draverBox",on:{"click":function($event){return _vm.ihtml.telCall(_vm.onlineDetail.extOrderDataDynamicData.driverMobile)}}},[_c('p',[_vm._v(_vm._s(_vm.onlineDetail.extOrderDataDynamicData.driverName))]),(_vm.onlineDetail.extOrderDataDynamicData.driverMobile)?_c('a',[_vm._v(_vm._s(_vm.onlineDetail.extOrderDataDynamicData.driverMobile))]):_vm._e()]),_c('div',{staticClass:"carInfo"},[_c('a',[_vm._v(_vm._s(_vm.onlineDetail.itsItemData.require_level_txt))]),(
              _vm.onlineDetail.extOrderDataDynamicData &&
              _vm.onlineDetail.extOrderDataDynamicData.driverCode
            )?[_c('a',[_vm._v(_vm._s(_vm.onlineDetail.extOrderDataDynamicData.driverCode))]),_c('a',[_vm._v(_vm._s(_vm.onlineDetail.extOrderDataDynamicData.driverColor))]),_c('a',[_vm._v(_vm._s(_vm.onlineDetail.extOrderDataDynamicData.driverCarType))])]:_vm._e()],2),_c('div',{staticClass:"addressBox"},[_c('span',[_c('span',{staticClass:"greenBc"}),_vm._v(_vm._s(_vm.onlineDetail.itsItemData.start_name))]),_c('span',[_c('span',{staticClass:"orangeBc"}),_vm._v(_vm._s(_vm.onlineDetail.itsItemData.end_name))])]),(
            _vm.onlineDetail.itsItemData &&
            _vm.onlineDetail.itsItemData.passenger &&
            _vm.onlineDetail.itsItemData.passenger.name
          )?_c('div',{staticClass:"passagerInfo",on:{"click":function($event){return _vm.ihtml.telCall(_vm.onlineDetail.itsItemData.passenger_phone)}}},[_c('p',[_vm._v(" 乘车联系人 "),_c('a',[_vm._v(_vm._s(_vm.onlineDetail.itsItemData.passenger.name))])]),_c('p',[_vm._v(" 乘车人电话 "),_c('span',[_vm._v(_vm._s(_vm.onlineDetail.itsItemData.passenger_phone))])]),_c('p',[_vm._v(" 第三方订单状态 "),_c('a',[_vm._v(_vm._s(_vm.onlineDetail.itsItemData.extStatusTxt || "暂无"))])]),(_vm.onlineDetail.itsItemData.orderTime)?_c('p',[_vm._v(" 下单时间 "),_c('a',[_vm._v(_vm._s(_vm.onlineDetail.itsItemData.orderTime || "暂无"))])]):_vm._e(),(_vm.onlineDetail.status == -1)?[_c('p',[_vm._v(" 取消方式 "),_c('a',[_vm._v(_vm._s(_vm.onlineDetail.itsItemData.isActiveCancel ? "用户取消" : "平台取消"))])]),_c('p',[_vm._v(" 等待时间 "),(_vm.onlineDetail.itsItemData.waitTime)?_c('a',[(_vm.onlineDetail.itsItemData.waitTime>60)?[_vm._v(" "+_vm._s((_vm.onlineDetail.itsItemData.waitTime/ 60).toFixed(2))+"min ")]:[_vm._v(" "+_vm._s(_vm.onlineDetail.itsItemData.waitTime)+"s ")]],2):_vm._e()])]:_vm._e(),(_vm.onlineDetail.status == 5)?[_c('p',[_vm._v(" 完成时间 "),_c('a',[_vm._v(_vm._s(_vm.onlineDetail.itsItemData.endTime || "暂无"))])]),_c('p',[_vm._v(" 上车时间 "),_c('a',[_vm._v(_vm._s(_vm.onlineDetail.itsItemData.startTime || "暂无"))])])]:_vm._e()],2):_vm._e(),(
            _vm.$route.query.isApproval && _vm.$route.query.passengersList.length > 0
          )?_c('div',{staticClass:"passengersListBox"},[_c('p',[_vm._v("实际乘车人")]),_vm._l((_vm.$route.query.passengersList),function(passenger,pindex){return _c('p',{key:pindex},[_c('span',[_vm._v(_vm._s(passenger.passengers))]),_c('span',[_vm._v(_vm._s(passenger.organization))]),_c('span',[_vm._v(_vm._s(passenger.department))])])}),_c('p',[_vm._v("用车备注")]),_c('p',[_vm._v(_vm._s(_vm.$route.query.passengersList[0].remark))])],2):_vm._e()]):_vm._e(),(
          !_vm.$route.query.isApproval &&
          !this.SmartStorage.get('viewOnly') &&
          (_vm.showsInfor || _vm.onlineDetail.extOrderStatus == 100)
        )?_c('div',{staticClass:"bottmBtnBox"},[_c('div',{staticClass:"funBtnBox"},_vm._l((_vm.onlineDetail.itsButtons),function(btn,indext){return _c('div',{key:indext,on:{"click":function($event){_vm.isloding && _vm.submitOrder(_vm.onlineDetail, btn)}}},[_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":'#' + btn.css}})]),_vm._v(" "+_vm._s(btn.txt)+" ")])}),0)]):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }