<template>
  <div>
    <v-touch v-on:swipeup="shrinkDetail" v-on:swipedown="shrinkDetail">
      <div class="infoDetail" v-if="onlineDetail.extOrderStatusTxt">
        <!-- <div class="statusBox">{{ onlineDetail.extOrderStatusTxt }}</div> -->
        <eventTag
          :classes="'statusBox'"
          :statusTxt="onlineDetail.extOrderStatusTxt"
          :status="onlineDetail.status"
        ></eventTag>
        <div class="topBtn" @click="shrinkDetail">
          <svg class="icon" aria-hidden="true">
            <use
              :xlink:href="showsInfor ? '#iconarrow-bottom' : '#iconarrow-top'"
            />
          </svg>
          <!-- <span></span> -->
        </div>
        <div class="mainInfor">
          <span class="iconBox">
            <svg class="icon" aria-hidden="true">
              <use :xlink:href="'#' + onlineDetail.itsItemData.sourceIcon" />
            </svg>
          </span>
          <div class="infoMian">
            <p>{{ onlineDetail.itsItemData.sourceTxt }}</p>
            <span>
              {{ onlineDetail.itsItemData.require_level_txt }}
              <template v-if="showsInfor">{{
                onlineDetail.itsItemData.departure_time ||
                onlineDetail.itsItemData.typeTxt ||
                onlineDetail.itsItemData.rule
              }}</template>
            </span>
          </div>
          <div
            class="priceMain"
            v-if="showsInfor || onlineDetail.extOrderStatus == 100"
          >
            <p>
              {{
                onlineDetail.status == "-1"
                  ? onlineDetail.settleAmount
                  : (onlineDetail.status == "5"
                      ? onlineDetail.settleAmount
                      : onlineDetail.amount) || 0 | currencyFormatter
              }}
            </p>
            <span>{{
              onlineDetail.status == "-1"
                ? "违约金"
                : onlineDetail.status == "5"
                ? "实际金额"
                : "预计金额"
            }}</span>
          </div>
          <div
            class="funBtnBox"
            v-else-if="
              !$route.query.isApproval && !this.SmartStorage.get('viewOnly')
            "
          >
            <template v-if="onlineDetail.extOrderStatus == 50">
              <div
                @click="
                  isloding &&
                    submitOrder(onlineDetail, onlineDetail.itsButtons[0])
                "
              >
                <svg class="icon" aria-hidden="true">
                  <use :xlink:href="'#' + onlineDetail.itsButtons[0].css" />
                </svg>
                {{ onlineDetail.itsButtons[0].txt }}
              </div>
              <div
                @click="
                  ihtml.telCall(
                    onlineDetail.extOrderDataDynamicData.driverMobile
                  )
                "
              >
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icondianhua-" />
                </svg>
                联系司机
              </div>
            </template>
            <div
              v-else-if="onlineDetail.extOrderStatus != 100"
              v-for="(btn, index) in onlineDetail.itsButtons.slice(0, 2)"
              @click="isloding && submitOrder(onlineDetail, btn)"
              :key="index"
            >
              <svg class="icon" aria-hidden="true">
                <use :xlink:href="'#' + btn.css" />
              </svg>
              {{ btn.txt }}
            </div>
          </div>
        </div>
        <div class="hideMain" v-if="showsInfor">
          <div
            class="draverBox"
            @click="
              ihtml.telCall(onlineDetail.extOrderDataDynamicData.driverMobile)
            "
          >
            <p>{{ onlineDetail.extOrderDataDynamicData.driverName }}</p>
            <a v-if="onlineDetail.extOrderDataDynamicData.driverMobile">{{
              onlineDetail.extOrderDataDynamicData.driverMobile
            }}</a>
          </div>
          <div class="carInfo">
            <a>{{ onlineDetail.itsItemData.require_level_txt }}</a>
            <template
              v-if="
                onlineDetail.extOrderDataDynamicData &&
                onlineDetail.extOrderDataDynamicData.driverCode
              "
            >
              <a>{{ onlineDetail.extOrderDataDynamicData.driverCode }}</a>
              <a>{{ onlineDetail.extOrderDataDynamicData.driverColor }}</a>
              <a>{{ onlineDetail.extOrderDataDynamicData.driverCarType }}</a>
            </template>
          </div>
          <div class="addressBox">
            <span><span class="greenBc"></span>{{ onlineDetail.itsItemData.start_name }}</span>
          
            <span><span class="orangeBc"></span>{{ onlineDetail.itsItemData.end_name }}</span>
          </div>
          <div
            class="passagerInfo"
            @click="ihtml.telCall(onlineDetail.itsItemData.passenger_phone)"
            v-if="
              onlineDetail.itsItemData &&
              onlineDetail.itsItemData.passenger &&
              onlineDetail.itsItemData.passenger.name
            "
          >
            <p>
              乘车联系人
              <a>{{ onlineDetail.itsItemData.passenger.name }}</a>
            </p>
            <p>
              乘车人电话
              <span>{{ onlineDetail.itsItemData.passenger_phone }}</span>
            </p>
            <p>
              第三方订单状态
              <a>{{ onlineDetail.itsItemData.extStatusTxt || "暂无" }}</a>
            </p>
            <p v-if="onlineDetail.itsItemData.orderTime">
              下单时间
              <a>{{ onlineDetail.itsItemData.orderTime || "暂无" }}</a>
            </p>
            <template v-if="onlineDetail.status == -1">
              <p>
                取消方式
                <a>{{
                  onlineDetail.itsItemData.isActiveCancel
                    ? "用户取消"
                    : "平台取消"
                }}</a>
              </p>
              <p>
                等待时间
                <a v-if="onlineDetail.itsItemData.waitTime">
                  <template v-if="onlineDetail.itsItemData.waitTime>60">
                     {{ (onlineDetail.itsItemData.waitTime/ 60).toFixed(2)}}min
                  </template>
                  <template v-else>
                    {{ onlineDetail.itsItemData.waitTime}}s
                  </template>
                </a> 
              </p>
            </template>
            <template v-if="onlineDetail.status == 5">
              <p>
                完成时间
                <a>{{ onlineDetail.itsItemData.endTime || "暂无" }}</a>
              </p>
              <p>
                上车时间
                <a>{{ onlineDetail.itsItemData.startTime || "暂无" }}</a>
              </p>
            </template>
          </div>
          <div
            class="passengersListBox"
            v-if="
              $route.query.isApproval && $route.query.passengersList.length > 0
            "
          >
            <p>实际乘车人</p>
            <p
              v-for="(passenger, pindex) in $route.query.passengersList"
              :key="pindex"
            >
              <span>{{ passenger.passengers }}</span>
              <span>{{ passenger.organization }}</span>
              <span>{{ passenger.department }}</span>
            </p>
            <p>用车备注</p>
            <p>{{ $route.query.passengersList[0].remark }}</p>
          </div>
        </div>
        <div
          class="bottmBtnBox"
          v-if="
            !$route.query.isApproval &&
            !this.SmartStorage.get('viewOnly') &&
            (showsInfor || onlineDetail.extOrderStatus == 100)
          "
        >
          <div class="funBtnBox">
            <div
              v-for="(btn, indext) in onlineDetail.itsButtons"
              @click="isloding && submitOrder(onlineDetail, btn)"
              :key="indext"
            >
              <svg class="icon" aria-hidden="true">
                <use :xlink:href="'#' + btn.css" />
              </svg>
              {{ btn.txt }}
            </div>
          </div>
        </div>
      </div>
    </v-touch>
  </div>
</template>
<script>
import eventTag from "@/views/common/eventTag";
export default {
  props: {
    onlineDetail: {
      type: Object,
    },
    submitOrder: {
      type: Function,
    },
    isloding: {
      type: Boolean,
    },
  },
  data() {
    return {
      showsInfor: false,
    };
  },
  components: {
    eventTag,
  },
  created() {
    if (this.SmartStorage.get("orderDetail")) {
      this.orderDetail = this.SmartStorage.get("orderDetail");
    }
  },
  methods: {
    shrinkDetail() {
      this.showsInfor = !this.showsInfor;
    },
    //定位
    async getsafetyConvoy(type) {
      let geolocation = {};
      if (type === "safety_center") {
        this.getDiDiBtns(type, geolocation);
        return;
      } else {
        let AMap = (this.AMap = window.AMap);
        AMap.plugin(["AMap.Geolocation"], () => {
          var geolocation = new AMap.Geolocation({
            timeout: 5000,
            GeoLocationFirst: false,
            maximumAge: 0, //定位结果缓存0毫秒，默认：0
          });
          let map = new AMap.Map("js-container", {
            view: new AMap.View2D({
              //创建地图二维视口
              zoom: 14, //设置地图缩放级别
              rotation: 0, //设置地图旋转角度
            }),
            lang: "zh_en", //设置地图语言类型，默认：中文简体
          });
          map.addControl(geolocation);
          geolocation.getCurrentPosition();
          AMap.event.addListener(geolocation, "complete", (data) => {
            geolocation = {
              lng: data.position.getLng(),
              lat: data.position.getLat(),
            };
            this.getDiDiBtns(type, geolocation);
          });
          AMap.event.addListener(geolocation, "error", (data) => {
            this.$iToast("获取位置失败！");
            this.getDiDiBtns(type);
          });
        });
      }
    },
    //行程分享，进入自己的h5页面，然后原生分享出去
    async getTripShareDetail(data) {
      if (data && data != "") {
        if (
          this.onlineDetail &&
          this.onlineDetail.extOrderStatus >= 30 &&
          this.onlineDetail.extOrderStatus < 60
        ) {
          let param = {
            itemId: this.orderDetail.ItemId,
          };
          this.services.GetTripShareDetail(param).then((res) => {
            if (res.success) {
              this.gosharePage(res.content, this.backShare);
            }
          });
        } else {
          this.$iToast("行程分享仅限司机接单后且未支付的订单");
        }
      }
    },
    backShare(data) {
      !data && this.$iToast("操作完成！");
    },
    gosharePage(data, callback) {
      // let isIphone =
      //   window.navigator.platform.indexOf("iphone") > -1 ||
      //   window.navigator.platform.indexOf("iPhone") > -1
      //     ? true
      //     : false;
      if (this.SmartStorage.get("isH5")) {
        window.location.href = data.share_url;
      } else {
        const options = {
          method: "SMShare.showShare",
          postData: data,
          callback: callback,
        };
        this.iJsBridge.call(options);
      }
    },
    //请求滴滴跳转地址
    async getDiDiBtns(type, geolocation) {
      console.log(this.orderDetail);
      let param = {
        itemId: this.orderDetail.ItemId,
      };
      if (geolocation && geolocation.lat) {
        param.Clat = geolocation.lat;
        param.Clng = geolocation.lng;
      }
      this.services.GetSafetyConvoy(param).then((res) => {
        if (res.success) {
          res.content.forEach((element) => {
            if (element.type === type) {
              if (this.SmartStorage.get("isH5")) {
                window.location.href = element.url;
              } else {
                this.gosafetyCenter(type, element.url, this.getTripShareDetail);
              }
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped="scoped" lang="scss">
@import "@/assets/platform/orderDetail.scss";
.tagSign {
  position: absolute;
  float: right;
  top: -0.1rem;
  right: -0.1rem;
}
</style>